

















import Vue from 'vue'
import Monogram from '../components/Monogram.vue'

export default Vue.extend({
    name: 'Home',
    components: {
        Monogram,
    },
    data() {
        return {}
    },
})
