import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            dark: {
                primary: '#0c151b',
                secondary: '#141e33',
                gold: '#b5b535',
                bronze: '#b5562b',
            },
        },
    },
})
