






import Vue from 'vue'

export default Vue.extend({
    name: 'AngledBox',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
})
