



























































































import Vue from 'vue'
import Logo from '../components/Logo.vue'
import LogoBox from '../components/LogoBox.vue'

export default Vue.extend({
    name: 'NavBar',
    components: {
        Logo,
        LogoBox
    },
    props: {
        hasReturn: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
    },
})
