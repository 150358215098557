




















import Vue from 'vue'

export default Vue.extend({
    name: 'LogoBox',
    props: {
        fill: {
            type: String,
            default: 'var(--v-primary-base)',
        },
        stroke: {
            type: String,
            default: null,
        },
        strokeWidth: {
            type: Number,
            default: 0.6,
        },
        width: {
            type: Number,
            default: 429.18,
        },
    },
    data() {
        return {
            hasStroke: !!this.stroke && !!this.strokeWidth,
            height: (70.055 / 427.16) * this.width,
        }
    },
    computed: {
        validStroke: function (): string | null {
            return this.hasStroke ? this.stroke : null
        },
        validWidth: function (): number | null {
            return this.hasStroke ? this.strokeWidth : null
        },
    },
})
