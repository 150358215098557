var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav-bar',{attrs:{"has-return":_vm.$vuetify.breakpoint.smAndDown}}),_c('v-container',{staticClass:"py-0 px-1",attrs:{"fluid":"","id":"frame"}},[_c('div',{staticClass:"frame-one",class:{ 'b-none': _vm.$vuetify.breakpoint.mdAndUp }},[_c('div',{staticClass:"frame-offset"},[_c('div',{staticClass:"frame-two",class:{ 'b-none': _vm.$vuetify.breakpoint.mdAndUp }},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-container',{staticClass:"py-0 px-1"},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"frame-one",class:{
                                            'b-none':
                                                _vm.$vuetify.breakpoint
                                                    .smAndDown,
                                        }},[_c('div',{staticClass:"frame-offset"},[_c('div',{staticClass:"frame-two",class:{
                                                    'b-none':
                                                        _vm.$vuetify.breakpoint
                                                            .smAndDown,
                                                }},[_c('v-container',[_c('v-card',{attrs:{"elevation":0,"color":"var(--v-primary-base)","href":"","outlined":"","tile":""}},[_c('div',{staticClass:"pa-4",staticStyle:{"border-top":"1px solid var(--v-gold-base)","border-left":"1px solid var(--v-gold-base)","border-right":"1px solid var(--v-gold-base)"}},[_c('v-img',{attrs:{"aspect-ratio":"1.7778","lazy-src":require('@/assets/items/lazy/' + _vm.item.order + '.jpg?vuetify-preload'),"src":require('../assets/items/full/' +
                                                                        _vm.item.order +
                                                                        '.jpg')}})],1),_c('div',{staticClass:"text-gold d-flex text-center align-center pt-2",staticStyle:{"margin-top":"-22px"}},[_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}}),_c('div',{staticClass:"px-3 pt-2 text-body-2",staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(_vm.item.mainTitle)+" ")]),_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}})],1),_c('div',{staticClass:"pt-1 text-gold text-center text-overline",staticStyle:{"line-height":"1em"}},[_vm._v(" "+_vm._s(_vm.item.katakana)+" ")]),_c('div',{staticClass:"text-gold d-flex text-center text-overline"},[_c('v-spacer'),_c('div',{staticClass:"pt-4 mb-2",staticStyle:{"border-bottom":"1px solid var(--v-gold-base)","width":"35px"}}),_c('v-spacer')],1),_c('div',{staticClass:"text-gold text-center text-overline"},[_vm._v(" "+_vm._s(_vm.item.japaneseName)+" ")]),_c('div',{staticClass:"text-gold d-flex text-center text-overline"},[_c('v-spacer'),_c('div',{staticClass:"pt-2 mb-4",staticStyle:{"border-bottom":"1px solid var(--v-gold-base)","width":"35px"}}),_c('v-spacer')],1),_c('div',{staticClass:"text-gold text-overline text-center px-6",staticStyle:{"line-height":"1.7em"}},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.item.subTitle))]),_vm._v(_vm._s(_vm.item.description)+" ")]),_c('div',{staticClass:"mt-3 pt-12 pr-1 text-gold d-flex text-center text-overline"},[_c('v-spacer'),_c('monogram',{attrs:{"animate":"","fill":"var(--v-gold-base)","width":35}})],1),_c('div',{staticClass:"pr-1 text-gold d-flex text-center text-overline"},[_c('v-spacer'),_c('div',{staticClass:"pt-3",staticStyle:{"border-bottom":"2px solid var(--v-gold-base)","width":"35px"}})],1)])],1)],1)])])])],1)],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }