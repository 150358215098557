















































































































































import Vue from 'vue'
import AngledBox from '../components/AngledBox.vue'
import NavBar from '../components/NavBar.vue'

export default Vue.extend({
    name: 'Menu',
    components: {
        AngledBox,
        NavBar,
    },
})
