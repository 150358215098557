var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav-bar'),_c('v-container',{staticClass:"py-0 px-1",attrs:{"fluid":"","id":"frame"}},[_c('div',{staticClass:"frame-one",class:{ 'b-none': _vm.$vuetify.breakpoint.mdAndUp }},[_c('div',{staticClass:"frame-offset"},[_c('div',{staticClass:"frame-two",class:{ 'b-none': _vm.$vuetify.breakpoint.mdAndUp }},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-container',{staticClass:"py-0 px-1"},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',{staticClass:"frame-one",class:{
                                            'b-none':
                                                _vm.$vuetify.breakpoint
                                                    .smAndDown,
                                        }},[_c('div',{staticClass:"frame-offset"},[_c('div',{staticClass:"frame-two",class:{
                                                    'b-none':
                                                        _vm.$vuetify.breakpoint
                                                            .smAndDown,
                                                }},[_c('v-container',_vm._l((_vm.$store
                                                            .state
                                                            .categories),function(categorie){return _c('v-row',{key:categorie.order},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}}),_c('angled-box',{attrs:{"title":categorie.name}}),_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}})],1)]),_vm._l((categorie.items),function(item){return _c('v-col',{key:item.order,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"elevation":0,"color":"var(--v-primary-base)","href":"","outlined":"","tile":"","to":{
                                                                    name:
                                                                        'Details',
                                                                    params: {
                                                                        order:
                                                                            item.order,
                                                                    },
                                                                }}},[_c('div',{staticClass:"pa-4",staticStyle:{"border-top":"1px solid var(--v-gold-base)","border-left":"1px solid var(--v-gold-base)","border-right":"1px solid var(--v-gold-base)"}},[_c('v-img',{attrs:{"aspect-ratio":"1.7778","lazy-src":require('@/assets/items/lazy/' + item.order + '.jpg?vuetify-preload'),"src":require('@/assets/items/thumbs/' + item.order + '.jpg?vuetify-preload')}})],1),_c('div',{staticClass:"text-gold d-flex text-center align-center pt-2",staticStyle:{"margin-top":"-22px"}},[_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}}),_c('div',{staticClass:"px-3 pt-2 text-body-2",staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(item.mainTitle)+" ")]),_c('v-divider',{staticStyle:{"border-color":"var(--v-gold-base)"}})],1)])],1)})],2)}),1)],1)])])])],1)],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }