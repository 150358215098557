import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        categories: ((ssItems, baseUrl, extension) => {
            interface ResLayout {
              name: string | null;
              items: any[];
              order: number
            }

            const res: ResLayout[] = [{ name: null, items: [], order: 0 }]
            ssItems.forEach((item: any) => {
                item.public = item.public === 'TRUE';
                if ('categories' in item)
                    res.push({
                        name: item.categories,
                        items: item.public ? [item] : [],
                        order: res.length,
                    })
                else
                  if (item.public)
                    res[res.length - 1].items.push(item)
            })
            return res.filter((cat: any) => cat.items.length > 0)
        })(
            [
                {
                    public: 'TRUE',
                    order: '01',
                    categories: 'APPETIZER',
                    mainTitle: 'TARTARE GATSBY',
                    katakana: 'タルタル ギャツビー',
                    japaneseName: '牛肉のタルタル',
                    subTitle:
                        '正々堂々と生肉を提供できるGATSBYの定番メニュー。',
                    description:
                        '日本の法律上、提供することが難しいヨーロッパ料理のタルタル。牛一頭を余すことなく隅まで美味しくいただこうという想いで、本来捨てられてしまうモモ肉の硬い部位も特別な調理方法で、柔らかく、お肉本来の深みや味を楽しんでいただけるGATSBYでしか食べることができない一皿です。',
                },
                {
                    public: 'TRUE',
                    order: '02',
                    mainTitle: 'GARBAGE SALAD',
                    katakana: 'ガベージ サラダ',
                    japaneseName: '規格外の焼き野菜と生野菜のサラダ',
                    subTitle:
                        '規格外。色んな形をした野菜。\nそれは個性。人間と一緒。',
                    description:
                        'スーパーや八百屋など、人の目に触れることなく破棄される可能性のある「規格外」と呼ばれる野菜を使用。ドレッシングなどを用いてひとつの味にするのではなく、苦みや甘み、野菜本来が持つ味や香りを最大限に引き出した調理方法で、野菜一つ一つの「個性」を味わっていただけるサラダです。',
                    urlLink:
                        '八百屋のタケシタ\nhttps://www.instagram.com/yaoyanotakeshita/',
                },
                {
                    public: 'FALSE',
                    order: '03',
                    mainTitle: 'ISONO TAI DESUYO',
                    katakana: 'イソノ タイ デスヨ',
                    japaneseName: '明石鯛のトリュフドレッシング',
                    subTitle:
                        '地のものを食べる。\n明石を食べて風土に感謝し、未来につなげる。',
                    description:
                        '海苔を食べて育ち、身から磯の香りが漂うほど香り高い兵庫県明石でとれた明石鯛に、ヴィネグレットトリュフを絡めた魚料理。極めてシンプルな調理方法でありながらインパクトのある一品です。',
                },
                {
                    public: 'TRUE',
                    order: '21',
                    mainTitle: 'ÇA VA? SABA',
                    katakana: 'サヴァ？ サバ',
                    japaneseName: 'サバのマリネ',
                    subTitle:
                        'なんでも無いサバを最良の手当てを施しフレンチスタイルのきずしに仕上げました。',
                    description:
                        '苦味、塩味、酸味、温度、香り、すべてが共存するシェフ渾身の一皿。',
                },
                {
                    public: 'TRUE',
                    order: '04',
                    mainTitle: 'GOMI SOUPE DE POISSON',
                    katakana: 'ゴミ スープ ド ポワソン',
                    japaneseName: '魚のスープ',
                    subTitle:
                        'ゴミ扱いされた魚を美味しいスープに。\n鱗も内臓もエラも全部スープにするんだよ。\n捨てる所なんか無い。',
                    description:
                        '瀬戸内海近郊で採れた新鮮な魚介類を使用し、鱗、エラ、内臓、骨の髄まで余すことなく、すべてのうまみを引き出したフランス ノルマンディ風の魚スープ。 価値の低いとされる魚介類も使用していることから、通称「ゴミスープ」とも言われる。魚のゼラチンのとろっとしたスープにバゲットがよく絡み、さらにグリュイエールチーズが加わることでより一層濃厚な味わいに。',
                },
                {
                    public: 'TRUE',
                    order: '05',
                    mainTitle: 'BEEF CONSOMMÉ',
                    katakana: 'ビーフ コンソメ',
                    japaneseName: 'コンソメスープ',
                    subTitle:
                        '余す事なく、ゆっくり時間をかけ、料理の技を屈指する。\nお店の味を左右するスープ。',
                    description:
                        '「完成された」の意味を持つ、誰もが知るフランス料理の代表的なスープ。ベースには鶏ガラのほか、ステーキで余った細切れの牛肉も無駄なく使用。卵白のタンパク質でアクや不純物などの雑味を吸い上げ、香味野菜で香づけしたクリアで味わい深い、究極のスープ。一般的に思われがちですが、50年後100年後も受け継いでいくべき、中世より続く歴史的な料理です。',
                },
                {
                    public: 'TRUE',
                    order: '06',
                    mainTitle: 'BISQUE DE HOMARD',
                    katakana: 'ビスク ド オマール',
                    japaneseName: 'オマール海老のスープ',
                    subTitle: 'フランス料理の醍醐味。豪快かつ優しいスープ。',
                    description:
                        'オマール海老特有のリッチで濃厚な、甲殻類ならではの香りを存分に楽しめるスープ。 他のメニューで余ったオマールの身や、コライユを使用した際に残った殻も使用。シンプルに凝縮されたオマールの風味を是非お楽しみください。',
                },
                {
                    public: 'FALSE',
                    order: '07',
                    mainTitle: 'HOMARD COCKTAIL SALAD',
                    katakana: 'オマール カクテル サラダ',
                    japaneseName: 'オマール海老のサラダ',
                    subTitle:
                        'ニューヨークのカクテルシュリンプをサラダに。\n資源を守る。ブルーシーフード。',
                    description:
                        '禁酒法でアルコールを抑制された時代、ブラッディメアリーの味に似ていることから人々に愛され、今ではパーティーフードのイメージがある「カクテルシュリンプ」。そんな料理をもっと気軽に楽しんでもらうため、GATSBYではサラダにした新しい形で提供。オマールのミソで作られた濃厚で深みのあるコライユソースがよく絡んだ一品です。',
                },
                {
                    public: 'TRUE',
                    order: '08',
                    categories: 'MEAT',
                    mainTitle: 'CHARCOAL STEAK',
                    katakana: 'チャコール ステーキ',
                    japaneseName: '牛肉ステーキ',
                    subTitle: '言うまでもない。スペシャリテ。',
                    description:
                        '炭火のみを熱源とするチャコールオーブン「ジャスパー」で焼き上げた究極の黒牛ステーキ。BOOZYSならではの火入れの技術と、ジャスパー特有の炭火の遠赤外線で表面をしっかりと焼き上げます。ザクっとした表面に中のお肉はジュワッと弾力の旨みが溢れ、ほのかな炭火の薫香が楽しめる、肉々しい食べ応えのあるステーキです。',
                },
                {
                    public: 'TRUE',
                    order: '09',
                    mainTitle: 'BLIND PIG & CHARMOULA',
                    katakana: 'ブラインド ピッグ & チャラモーラ',
                    japaneseName: '豚バラ肉のスパイシースペアリブ',
                    subTitle:
                        'BBQ。世界にはまだまだ知らない美味しい料理がある。',
                    description:
                        '200度で焼き上げられ、豚の旨味をぎゅっと凝縮させたスペアリブ。スパイスのガリガリ感と、柔らかくジューシーな脂身で食感を楽しむことができる一品。「チャラモーラ」と呼ばれる甘っずっぱいソースと、付け合わせのミントが鼻から抜け、くどくならずさっぱり爽やかに。お供には、ジン系のカクテルがおすすめです。',
                },
                {
                    public: 'TRUE',
                    order: '10',
                    mainTitle: 'KAMONEGI',
                    katakana: 'カモネギ',
                    japaneseName: '鴨葱',
                    subTitle:
                        '好都合な料理。\nフォアグラを作るお肉も美味しく食べたい。',
                    description:
                        'フォアグラを取るために育てられたハンガリー産「まぐれガモ」を使用。付け合わせには、さっと熱した細切りのネギを添えた、GATSBYが考える新しい「鴨葱」。シンプルな調理技法で、本来の鴨の風味や味わいをお楽しみください。',
                },
                {
                    public: 'TRUE',
                    order: '11',
                    mainTitle: 'GOLDEN CHICKEN',
                    katakana: 'ゴールデン チキン',
                    japaneseName: 'フランス ブレス産鶏のロースト',
                    subTitle:
                        'ブレス鶏を丸ごと焼く。\nGATSBYだから出来る事。\n世界一美味しい鶏を世界一美味しく食べる。',
                    description:
                        '旨みが強く、筋肉質でありながら独特の柔らかさを持つ日本には存在しない、統制原産地呼称（AOC）に登録されているフランスのブレス鶏を使用。骨付き半羽で焼くことで、保湿したままドリップが出ず、うまみを残し身はふっくらやさしく、皮はパリッとジューシーに焼き上げます。',
                },
                {
                    public: 'TRUE',
                    order: '12',
                    categories: 'FISH',
                    mainTitle: 'GRILL OCTOPUS',
                    katakana: 'グリル オクトパス',
                    japaneseName: 'タコのグリル',
                    subTitle:
                        '明石の蛸。\n漁師は気がいい。素晴らしい仕事をする。',
                    description:
                        'メインシェフ津田が、ニューヨークのレストランで衝撃を受けたタコのグリル。スパイスでマリネした明石蛸をオーブンで焼き上げた、シンプルだけど奥深い思い出の詰まった料理。トマトを凝縮させたサルサ風のセミドライソースが、タコの香りと食感にマッチします。',
                },
                {
                    public: 'TRUE',
                    order: '13',
                    mainTitle: 'HIBISCUS LOVE SALMON',
                    katakana: 'ハイビスカス ラブ サーモン',
                    japaneseName: 'サーモンのスパイス焼き',
                    subTitle: '北欧料理をGATSBY STYLEで。\n文化と進化。',
                    description:
                        '塩・砂糖・ハーブ・千切りのビーツをサーモンに塩漬けし、ジャスパーで焼き上げた、北欧の家庭料理。この料理の起源は中世にさかのぼり、漁師が鮭を塩漬けにし、満潮時の海面よりも高い砂浜に埋めて軽く発酵させたことから始まっています。マリネにはハイビスカスや、薔薇の花びらを使用した華やかなサーモングリルに。',
                },
                {
                    public: 'TRUE',
                    order: '14',
                    mainTitle: 'FISH RÔTI & CHIMICHURI',
                    katakana: 'フィッシュ ロティ & チミチュリ',
                    japaneseName: '焼き魚 ハーブソース',
                    subTitle:
                        '近海の魚でシンプルだけど奥深い。\nあらゆる調理技術と思いを込めた。',
                    description:
                        '旬の魚を、塩水や香草、野菜が入った「ブイヨンソミュール」に一日漬け、臭みを消した後、フライパンに載せたまま、ジャスパーで焼き上げます。バターで優しく保湿しながら焼きあげることで、ふわっと柔らかく仕上がります。アルゼンチン発祥の香草をしようしたさっぱりとした味わいのヴィーガンソースを添えてお召し上がりください。',
                },
                {
                    public: 'TRUE',
                    order: '15',
                    mainTitle: 'HOMARD RÔTI',
                    katakana: 'オマール ロティ',
                    japaneseName: '焼きオマール海老',
                    subTitle:
                        'ジャスパーの炭火で最大限に引き出されたオマールの味。',
                    description:
                        'バターの優しい風味が漂う、オマールのロースト。優しくアロゼしながら、殻ごとジャスパーで焼かれたふっくら柔らかいオマールの身に、殻からとった出汁がよく絡み、オマールを余すことなく丸ごと楽しめる一品です。',
                },
                {
                    public: 'TRUE',
                    order: '22',
                    mainTitle: 'NARUTO RISOTTO & BLACK TRUFFLE',
                    katakana: 'ナルトリゾット ブラックトリュフ',
                    japaneseName: 'ハリイカと海苔と黒トリュフのリゾット',
                    subTitle:
                        'イカの鳴門巻きからインスピレーションを得た一皿。',
                    description:
                        '明石のハリイカを海苔とマッチングさせ海苔の香りに近いフランス産黒トリュフを使用したシメの一品。',
                },
                {
                    public: 'TRUE',
                    order: '23',
                    mainTitle: 'MATSUBA CRAB',
                    katakana: 'マツバ クラブ',
                    japaneseName: '松葉カニの炭焼きとグラタン',
                    subTitle:
                        '兵庫県美方郡香美町の柴山漁港、松栄丸より届く松葉カニ。',
                    description:
                        '市場には並ばない足折れのカニを調理。脚は炭焼き、胴体はグラタンに。GATSBYが表現するカニ料理。季節限定の兵庫の冬を是非。',
                },
                {
                    public: 'TRUE',
                    order: '16',
                    categories: 'VEGETABLE',
                    mainTitle: 'CAROTTES BRÛLÉES & DUKKAH',
                    katakana: 'キャロット ブリュレ & デュカ',
                    japaneseName: '人参ロースト スパイス',
                    subTitle:
                        '人参の起源に迫る。\n間引かれる人参はここまで美味くなる。',
                    description:
                        '育てる途中で間引きされてしまった「間引き人参」を皮ごと丸焼きにし、中東料理の調味料「デュカ」やナッツを絡め、ザクザクとした食感が楽しい一品。ジャスパーにより凝縮された人参本来の香り・甘み・苦み、全てを味わうことができます。',
                    urlLink:
                        '八百屋のタケシタ\nhttps://www.instagram.com/yaoyanotakeshita/',
                },
                {
                    public: 'TRUE',
                    order: '17',
                    mainTitle: 'CURRYFLOWER STEAK',
                    katakana: 'カリーフラワー ステーキ',
                    japaneseName: 'カリフラワーロースト スパイス',
                    subTitle:
                        'ヴィーガンもベジタリアンも同じ食卓を囲もう。\nただ、偏った思考はどうかと思う。\n僕はヴィーガンに健康なお肉を食べさせたい。\nだからヴィーガンをする。',
                    description:
                        'フェンネルシード・コリアンダーシードなどミックススパイスをつけ、カリッと焼き上げたお野菜ステーキ。ボリュームのあるカリフラワーと、噛むごとに広がるスパイスの香りやガリガリした舌触りがやみつきに。ヴィーガンの方にも、そうでない方も、同じ食べ方・味付けで「美味しい」を共有できる一皿です。',
                    urlLink:
                        '八百屋のタケシタ\nhttps://www.instagram.com/yaoyanotakeshita/',
                },
                {
                    public: 'TRUE',
                    order: '18',
                    categories: 'DESSERT',
                    mainTitle: 'MILLE FEUILLE THE BAKE',
                    katakana: 'ミルフィーユ ザ ベイク',
                    japaneseName: 'THE BAKEのミルフィーユ',
                    subTitle:
                        'しっかり焼き込んだパイとリッチなクリーム。\nその背景には今後の課題もある。',
                    description:
                        '姉妹店の「THE BAKE」で香ばしく焼いたパイ生地に、カスタードクリームをたっぷりと重ねたミルフィーユ。カスタードクリームに使われた牛乳は、淡路島にある環境に配慮し続ける北坂養鶏場の卵を使用し、濃厚で風味豊かな味わいに。サクサクでエアリーなパイ生地と、甘すぎないカスタードクリームで、食後のデザートにピッタリです。',
                    urlLink:
                        'THE BAKE\nhttps://www.instagram.com/thebake.boozys/',
                },
                {
                    public: 'TRUE',
                    order: '24',
                    mainTitle: 'VEGAN ICE CREAM',
                    katakana: 'ビーガン アイス クリーム',
                    japaneseName: 'ビーガン アイス クリーム',
                    subTitle:
                        'Harlowが手掛ける、ビーガンの方でも食べれる豆乳ベースのアイスクリーム。',
                    description:
                        '季節によって変化するフレーバーもお楽しみください。詳しくはお気軽にスタッフまで。',
                },
                {
                    public: 'TRUE',
                    order: '25',
                    categories: 'BAR FOOD',
                    mainTitle: 'OYSTER & WHISKEY',
                    katakana: 'オイスター & ウイスキー',
                    japaneseName: '生牡蠣',
                    subTitle:
                        '北海道仙鳳趾産の牡蠣に、',
                    description:
                        'スコットランド・アイラ島『BOWMORE』の香りを纏わせた、ウイスキーに合う生牡蠣です。'
                },
                {
                    public: 'TRUE',
                    order: '26',
                    mainTitle: 'FRIED POTATOES',
                    katakana: 'フライドポテト',
                    japaneseName: 'フライドポテト',
                    subTitle:
                        'ラードでカラッと揚げたフライドポテト。',
                    description:
                        'チーズバーガーとの相性抜群のバーフードです。バーでのドリンクのおつまみとしても是非。'
                },
                {
                    public: 'TRUE',
                    order: '27',
                    mainTitle: 'CRISPY CHICKEN',
                    katakana: 'クリスピーチキン',
                    japaneseName: 'フライドチキン',
                    subTitle:
                        '丁寧にマリネした骨付き鶏モモ肉をラードでカラッと揚げ、',
                    description:
                        '香ばしいかおり、旨味を纏ったクラシックなフライドチキンです。'
                },
                {
                    public: 'TRUE',
                    order: '28',
                    mainTitle: 'CHEESEBURGER',
                    katakana: 'チーズバーガー',
                    japaneseName: 'チーズバーガー',
                    subTitle:
                        '『THE BAKE』のバンズを使用し、',
                    description:
                        '牛100%パティ・チーズ・玉ねぎ・ソースのみのシンプルなハンバーガー。100年前のニューヨークをコンセプトとしたGATSBYが提案する、単純で豪快な〆バーガー。'
                },
                {
                    public: 'TRUE',
                    order: '29',
                    mainTitle: 'COCKTAIL SHRIMP',
                    katakana: 'カクテルシュリンプ',
                    japaneseName: 'カクテルシュリンプ',
                    subTitle:
                        'THE GATSBYが提案する新しい「カクテルシュリンプ」。',
                    description:
                        'オマール海老１尾、海老６尾、生牡蠣２個を、レモン 、ワインビネガー、オマールマヨネーズの３種ソースで召し上がって頂きます。'
                },
                {
                    public: 'TRUE',
                    order: '18',
                    mainTitle: 'MILLE FEUILLE THE BAKE',
                    katakana: 'ミルフィーユ ザ ベイク',
                    japaneseName: 'THE BAKEのミルフィーユ',
                    subTitle:
                        'しっかり焼き込んだパイとリッチなクリーム。\nその背景には今後の課題もある。',
                    description:
                        '姉妹店の「THE BAKE」で香ばしく焼いたパイ生地に、カスタードクリームをたっぷりと重ねたミルフィーユ。カスタードクリームに使われた牛乳は、淡路島にある環境に配慮し続ける北坂養鶏場の卵を使用し、濃厚で風味豊かな味わいに。サクサクでエアリーなパイ生地と、甘すぎないカスタードクリームで、食後のデザートにピッタリです。',
                    urlLink:
                        'THE BAKE\nhttps://www.instagram.com/thebake.boozys/',
                },
                {
                    public: 'TRUE',
                    order: '19',
                    categories: 'APÉRITIF',
                    mainTitle: 'CRACKER',
                    katakana: 'クラッカー',
                    japaneseName: 'クラッカー',
                    subTitle:
                        '挨拶がてらの突き出し。\nそのままでも良し。アルコールと合わせても良し。\nそれぞれが楽しみたいスタイルで。',
                    description:
                        'ローストし風味づけた胚芽が生地にフランスのイズニー社の香り高いバターが練り込まれた「THE BAKE」のクラッカー。ザクザクとした穀物感と程よい塩っけは、乾杯のお供にピッタリです。',
                    urlLink:
                        'THE BAKE\nhttps://www.instagram.com/thebake.boozys/',
                },
                {
                    public: 'TRUE',
                    order: '20',
                    mainTitle: 'BAGUETTE YAMA',
                    katakana: 'バゲット ヤマ',
                    japaneseName: 'バゲット ヤマ',
                    subTitle:
                        'バターをひと塗りする時間も楽しんでほしい。\nオーセンティックなバケット。',
                    description:
                        'フランス産の小麦を使用し、水・塩・イーストのみで作られた、小麦の香りがとても強いクラシカルなバゲット。外はざっくり中はふわふわの生地に、フランスのイズニー社の発酵バターと、スペインの山で取れるアンセストラルの塩をつけてお召し上がりください。',
                    urlLink:
                        'THE BAKE\nhttps://www.instagram.com/thebake.boozys/',
                },
            ],
            '../assets/items/',
            '.jpg'
        ),
    },
    mutations: {},
    actions: {},
    modules: {},
})
