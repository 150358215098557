

























































































































































































import Vue from 'vue'
import NavBar from '../components/NavBar.vue'
import Monogram from '../components/Monogram.vue'

export default Vue.extend({
    name: 'Details',
    components: {
        NavBar,
        Monogram
    },
    data() {
        return {
            item: ((store, key) => {
                let res: any = null
                try {
                    store.forEach((categorie: any) => {
                        if (res) throw ''
                        res = categorie.items.find((item: any) => item.order === key)
                    })
                } catch (e) { return res; }
                return res;
            })(this.$store.state.categories, this.$route.params.order),
        }
    },
})
