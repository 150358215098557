






















import Vue from 'vue'

export default Vue.extend({
    name: 'Monogram',
    props: {
        animate: {
            type: Boolean,
            default: false,
        },
        fill: {
            type: String,
            default: 'var(--v-gold-base)',
        },
        gradient: {
            type: Object,
            default: null,
        },
        stroke: {
            type: String,
            default: null,
        },
        strokeWidth: {
            type: Number,
            default: 0.6,
        },
        width: {
            type: Number,
            default: 45.005,
        },
    },
    data() {
        return {
            hasGradient: !!this.gradient && !!this.gradient.from && !!this.gradient.to,
            hasStroke: !!this.stroke && !!this.strokeWidth,
            height: (31.272 / 45.005) * this.width
        }
    },
    computed: {
        cssGradient: function (): Record<string,string> | null {
            return this.hasGradient
                ? {
                      '--gradient-from': this.gradient.from,
                      '--gradient-to': this.gradient.to,
                  }
                : null
        },
        validStroke: function (): string | null {
            return !this.hasGradient && this.hasStroke ? this.stroke : null
        },
        validWidth: function (): number | null {
            return this.hasGradient || this.hasStroke ? this.strokeWidth : null
        },
    },
})
